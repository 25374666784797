import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Switch, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import * as ApplicationApi from '../utils/ApplicationApi';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    padding: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  switchLabel: {
    marginRight: '10px',
  },
  searchInput: {
    width: '200px',
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  smallerTable: {
    width: '80%',
  },
  createTicketButton: {
    color: '#FF9800',
  },
  separatorContainer: {
    width: '60%',
  },
  separator: {
    margin: '40px auto',
    borderTop: '1px dashed #ccc',
    width: '100%',
  }
}));

const Account = () => {
  const classes = useStyles();
  const [contracts, setContracts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedData, setExpandedData] = useState({});
  const [loadingExpanded, setLoadingExpanded] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      setLoading(true);
      try {
        const response = await ApplicationApi.ListContract();

        if (response.status) {
          setContracts(response.data);
        } else {
          console.error(response.reason);
        }
      } catch (error) {
        console.error('Error fetching contracts:', error);
      }
      
      
      setLoading(false);
    };

    fetchContracts();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleRowClick = async (contract, contractId) => {
    if (expandedRow === contractId) {
      setExpandedRow(null);
      setExpandedData({});
    } else {
      setLoadingExpanded(true);
      setExpandedRow(contractId);
      setExpandedData(contract);
      setLoadingExpanded(false);
    }
  };

  const renderExpandedRow = (data) => {
    if (loadingExpanded) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell sx={{ padding: '56px' }} colSpan={6}>
           <Table>
              <TableHead>
                <TableRow>
                <TableCell className={classes.tableHeader}>Item</TableCell>
                  <TableCell className={classes.tableHeader}>Total Contract Volume</TableCell>
                  <TableCell className={classes.tableHeader}>Average Contract Volume</TableCell>
                  <TableCell className={classes.tableHeader}>Total Consumption</TableCell>
                  <TableCell className={classes.tableHeader}>Consumption Last Month</TableCell>
                  <TableCell className={classes.tableHeader}>Consumption This Month</TableCell>
                  <TableCell className={classes.tableHeader}>Total Sales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ minWidth: '30vw' }} title={item.item?.value}>{item.item?.value.substring(0, 50)}</TableCell>
                  <TableCell>{item.contractVolume?.value}</TableCell>
                  <TableCell>{item.monthlyContactVolume?.value}</TableCell>
                  <TableCell>{item.totalConsumption?.value}</TableCell>
                  <TableCell>{item.consumptionLATotal?.value}</TableCell>
                  <TableCell>{item.ConsumptionLTD?.value}</TableCell>
                  <TableCell>{item.salesTotal?.value}</TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" style={{ marginTop: '15px' ,marginBottom: '10px', textAlign: 'center', paddingBottom: '15px'}}>My Contracts</Typography>
      <div className={classes.header}>
        <TextField
          className={classes.searchInput}
          label="Search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={handleSearch}
        />
      </div>
      {loading && (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
          </div>
        )}
      <TableContainer component={Paper} className={classes.smallerTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Contact Name</TableCell>
              <TableCell className={classes.tableHeader}>P&L</TableCell>
              <TableCell className={classes.tableHeader}>Start Date</TableCell>
              <TableCell className={classes.tableHeader}>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            contracts
              .filter((contract) => {
                const contractData = JSON.stringify(contract).toLowerCase();
                return (
                  contractData.includes(searchText.toLowerCase())
                );
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((contract, index) => (
                <React.Fragment key={index}>
                  <TableRow onClick={() => handleRowClick(contract, index)} style={{ cursor: 'pointer' }}>
                    <TableCell>{contract.name?.value}</TableCell>
                    <TableCell>{contract.pl?.text}</TableCell>
                    
                    <TableCell>{contract.startDate?.value || "N/A"}</TableCell>
                    <TableCell>{contract.endDate?.value || "N/A"}</TableCell>
                    <TableCell>
                      {expandedRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </TableCell>
                  </TableRow>
                  
                 

                  {expandedRow === index && renderExpandedRow(contract.items)}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={contracts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </div>
  );
};

export default Account;
