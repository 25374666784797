import config from "./config.json";

const base_url = config.env === "PRODUCTION" ? config.api.prod_url : config.api.dev_url

export async function IsAuthenticated(response){
    if(!response || (response.status === false && response.reason === "Unauthorized")){
        localStorage.clear();
        window.location.href = config.app_routes.login;
    }
}

export async function request(api, method, headers, body = []) {
    const url = base_url + api;

    const request_action = await fetch(url, {
        method: method,
        body: method === "GET" ? null : JSON.stringify(body),
        headers: headers
    });

    return request_action;
}

export async function PortalScript(body, api, method){
    const headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : ""
    }

    const response = await request(api, method, headers, body);
    const data = await response.json();

    return data;
}

export async function ListContract(body){
    const api = config.routes.listContract;
    const method = "POST";

    const data = await PortalScript(body, api, method);

    return data;
}

export async function ValidateCustomer(body){
    const api = config.routes.validateCustomer;
    const method = "POST";

    const data = await PortalScript(body, api, method);

    return data;
}

export async function Auth(body){
    const api = config.routes.auth;
    const method = "POST";

    const data = await PortalScript(body, api, method);

    return data;
}