import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import config from './Pages/utils/config.json';

import WithAuthCheck from './Pages/utils/WithAuthCheck';
import WithHeader from './Pages/utils/WithHeader';

import Auth from './Pages/Auth/Auth';
import Account from './Pages/Account/Account';

function App() {
  // auth check for each account route
  const AuthAccountPage = WithAuthCheck(Account);

  const AccountPage = WithHeader(AuthAccountPage);

  return (
    <>
      <Router>
        <Routes>
          <Route path={config.app_routes.login} element={<Auth/>} />
          <Route path={config.app_routes.account} element={<AccountPage/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;